import {
  PostBlockSessionCommit,
  PostBlockSessionRequestResponseBody,
  BlockSessionKeyOwnershipClaim,
  AuthTokenJWTPayload,
} from "../../common-ts/apimodel";
import {
  decodeBase64AsArrayBuffer,
  encodeArrayBufferAsBase64,
  encodeArrayBufferAsBase64Url,
} from "../../common-ts/bytes";
import {
  ecdsaP256ExportPublicKeyRaw,
  ecdsaP256GenerateKeyPair,
  ecdsaP256SignMessage,
} from "../../common-ts/crypto";
import apiURL from "./apiroute";
import { signOnYubikey } from "./yubikey";

export async function createBlockSessionCredentials(
  authData: AuthTokenJWTPayload,
  blockSessionName: string | undefined
): Promise<{
  keyPair: CryptoKeyPair;
  blockSession: PostBlockSessionRequestResponseBody;
}> {
  const blockSession: PostBlockSessionRequestResponseBody = await (
    await fetch(apiURL("/block-session/request"), {
      credentials: "include", // send JWT cookie "authToken"
      method: "POST",
    })
  ).json();

  console.log("sessionDetails", blockSession);
  const persistentKeyYubikeyKeyHandle = decodeBase64AsArrayBuffer(
    blockSession.publicKey.yubikeyKeyIdB64
  );

  // 1. Generate a new ecdsa p-256 key pair for the session
  const sessionKeyPair = await ecdsaP256GenerateKeyPair(true); // true: exportable

  // 2. Get the public key bytes
  const sessionPublicKeyBytes = await ecdsaP256ExportPublicKeyRaw(
    sessionKeyPair.publicKey
  );

  // We prove to Genueen that we own the session private key
  // by signing the challenge
  const relyingPartySessionKeyProof = await ecdsaP256SignMessage(
    sessionKeyPair.privateKey,
    new TextEncoder().encode(
      JSON.stringify({
        publicKey: encodeArrayBufferAsBase64Url(sessionPublicKeyBytes),
        sessionChallenge: blockSession.challengeB64,
        sessionId: blockSession.sessionId,
      })
    )
  );

  // //////////////////////////////////////////////////////////////////////////
  // We prove to the public that we own the session private key, certified by the persistent private key
  // //////////////////////////////////////////////////////////////////////////

  const sessionOwnershipClaim: BlockSessionKeyOwnershipClaim = {
    topic: "genueen.com:session_key_ownership_claim",
    session: {
      id: blockSession.sessionId,
      start: blockSession.initDate,
      expires: blockSession.expires,
    },
    certifyingKeyId: blockSession.publicKey.id,
    user_id: blockSession.publicKey.user_id,
    twitter_username: authData.user.twitter_username,
  };
  const sessionOwnershipClaimJSON = JSON.stringify(sessionOwnershipClaim);

  // we sign the claim with the session private key
  let sessionKeyOwnershipClaimSignature = await ecdsaP256SignMessage(
    sessionKeyPair.privateKey,
    new TextEncoder().encode(sessionOwnershipClaimJSON)
  );

  // we then sign the claim signature with the persistent private key
  // to signal that the owner of the persistent private key certifies
  // the session private key as theirs
  let persistentKeySessionCertificate = await signOnYubikey(
    persistentKeyYubikeyKeyHandle,
    sessionKeyOwnershipClaimSignature
  );

  const sessionCommit: PostBlockSessionCommit = {
    sessionId: blockSession.sessionId,
    algorithm: "ecdsa-p256",
    sessionKeyPublicB64: encodeArrayBufferAsBase64(sessionPublicKeyBytes),
    sessionKeyProofB64Url: encodeArrayBufferAsBase64Url(
      relyingPartySessionKeyProof
    ),
    yubikeyKeyIdB64Url: encodeArrayBufferAsBase64Url(
      persistentKeyYubikeyKeyHandle
    ),

    publicOwnershipProof: {
      sessionKeyProof: {
        claimJSON: sessionOwnershipClaimJSON,
        // FIXME(jerome): normalize base64, use Base64Url everywhere since we can't change WebAuthn's API
        signature: encodeArrayBufferAsBase64Url(
          sessionKeyOwnershipClaimSignature
        ),
      },
      persistentKeyCertificate: {
        authenticatorData: encodeArrayBufferAsBase64Url(
          persistentKeySessionCertificate.authenticatorData
        ),
        clientDataJSON: new TextDecoder().decode(
          persistentKeySessionCertificate.clientDataJSON
        ),
        derEncodedSignature: encodeArrayBufferAsBase64Url(
          persistentKeySessionCertificate.derEncodedSignature
        ),
      },
    },
    unsigned: {
      name: blockSessionName,
      public: true /*FIXME(jerome): make this parametrizable*/,
    },
  };

  await fetch(apiURL("/block-session/commit"), {
    credentials: "include", // send JWT cookie "authToken"
    method: "POST",
    body: JSON.stringify(sessionCommit),
  });

  return { keyPair: sessionKeyPair, blockSession };
}
