import React from "react";
import { Link, useNavigate } from "react-router-dom";
import apiURL from "../apiroute";
import logInWithTwitterInitiate from "../logInWithTwitterInitiate";
import { AppContext } from "./AppContext";
import GrayTwitterIcon from "./GrayTwitterIcon";

export default function AuthStatus() {
  const { authStatus, setAuthStatus } = React.useContext(AppContext);
  const navigate = useNavigate();

  const handleLogin = async () => {
    await logInWithTwitterInitiate();
  };

  const handleLogout = async () => {
    await fetch(apiURL("/auth/logout"), {
      credentials: "include",
    });
    setAuthStatus({ status: "unauth" });
    navigate("/");
  };

  return (
    <div>
      {authStatus && authStatus.status === "unauth" ? (
        <p>
          <button
            onClick={handleLogin}
            className="text-orange-500 hover:text-orange-400"
          >
            Log In with Twitter
          </button>
        </p>
      ) : null}
      {authStatus && authStatus.status === "auth" ? (
        <p className="text-gray-500">
          <Link
            to="/account/manage-keys"
            className="text-gray-500 hover:text-orange-400"
          >
            <GrayTwitterIcon /> {authStatus.data.user.twitter_username}
          </Link>
          {" | "}
          <button
            onClick={handleLogout}
            className="text-gray-500 hover:text-orange-400"
          >
            Log Out
          </button>
        </p>
      ) : null}
    </div>
  );
}
