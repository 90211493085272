import React from "react";
import { Link } from "react-router-dom";
import PageContent from "./PageContent";

export default function LandingCapture() {
  return (
    <PageContent title="Capture and sign recordings.">
      <PageContent.MainLink to="/capture/pre-recorded">
        I have a pre-recorded media.
      </PageContent.MainLink>
      <PageContent.MainLink to="/capture/live">
        I want to capture live.
      </PageContent.MainLink>
    </PageContent>
  );
}
