import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "./AppContext";
import { AuthStatusResponseAuth } from "../../../common-ts/apimodel";
import { createBlockSessionCredentials } from "../createSessionCredentials";
import { encodeArrayBufferAsBase64 } from "../../../common-ts/bytes";
import {
  ecdsaP256ExportPrivateKeyPkcs8,
  ecdsaP256ExportPublicKeyRaw,
} from "../../../common-ts/crypto";
import PageContent from "./PageContent";

interface CreateSessionAndStartRecordingProps {
  blockSessionName: string|undefined;
  startRecording: (
    blockSessionKeyPair: CryptoKeyPair,
    blockSession: any
  ) => void;
}

export default function CreateSessionAndStartRecording(
  props: CreateSessionAndStartRecordingProps
) {
  const {
    authStatus,
    blockSessionKeyPair,
    setBlockSessionKeyPair,
    setBlockSession,
  } = useContext(AppContext);
  const authData = (authStatus as AuthStatusResponseAuth).data;

  // FIXME(jerome): no any
  const [exportedKeys, setExportedKeys]: any = useState(null);

  async function handleCreateBlockSessionCredentials() {
    const { keyPair, blockSession } = await createBlockSessionCredentials(
      authData,
      props.blockSessionName,
    );
    setBlockSessionKeyPair(keyPair);
    setBlockSession(blockSession);
    props.startRecording(keyPair, blockSession);
  }

  useEffect(() => {
    async function exportKeys() {
      if (blockSessionKeyPair) {
        const privKeyStr = encodeArrayBufferAsBase64(
          await ecdsaP256ExportPrivateKeyPkcs8(blockSessionKeyPair.privateKey)
        );

        const pubKeyStr = encodeArrayBufferAsBase64(
          await ecdsaP256ExportPublicKeyRaw(blockSessionKeyPair.publicKey)
        );

        setExportedKeys({ privKeyStr, pubKeyStr });
      }
    }

    exportKeys();
  }, [blockSessionKeyPair]);

  return (
    <PageContent.MainButton
      onClick={async () => await handleCreateBlockSessionCredentials()}
    >
      Capture Live Audio Session
    </PageContent.MainButton>
  );
}
