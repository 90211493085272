import React from "react";
import { AppContext } from "../AppContext";

interface NeedsAuthProps {
  children: React.ReactNode;
}

export default function NeedsAuth(props: NeedsAuthProps) {
  const { authStatus } = React.useContext(AppContext);
  return <>{authStatus ? props.children : <div>Loading...</div>}</>;
}
