import { AuthTokenJWTPayload } from "../../common-ts/apimodel";
import apiURL from "./apiroute";

export default async function logInWithTwitterConfirm(
  oauthToken: string,
  oauthVerifier: string
): Promise<AuthTokenJWTPayload> {
  try {
    const response = await fetch(apiURL("/auth/twitter/callback"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ oauthToken, oauthVerifier }),
    });

    const jwt: AuthTokenJWTPayload = await response.json();
    return jwt;
  } catch (error) {
    alert("Error during Twitter callback handling: " + error);
    throw error;
  }
}
