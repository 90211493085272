import { z } from "zod";
import {
  ecdsaP256RawSignatureFromDERSignature,
  sha256,
  ecdsaP256VerifyMessage,
} from "./crypto";
import { b64UrlToUint8Array, concatArrayBuffers } from "./bytes";
import {
  UserAPIModel,
  BlockSessionAPIModel,
  BlockSessionKeyOwnershipClaim,
  BlockSessionKeyOwnershipClaimSchema,
  BlockSessionKeyPublicOwnershipProof,
} from "./apimodel";
import { BlockSessionDBModel } from "../worker/src/dbmodel";

export async function assertBlockSessionKeyPublicOwnershipProof(
  publicOwnershipProof: BlockSessionKeyPublicOwnershipProof,
  blockSession: BlockSessionDBModel | BlockSessionAPIModel,
  user: UserAPIModel,
  blockSessionPublicKey: CryptoKey,
  persistentPublicKey: CryptoKey
) {
  // 1. Validate claimJSON content
  const sessionKeyOwnershipClaimJSON =
    publicOwnershipProof.sessionKeyProof.claimJSON;

  let ownershipClaim: BlockSessionKeyOwnershipClaim;
  try {
    ownershipClaim = BlockSessionKeyOwnershipClaimSchema.parse(
      JSON.parse(sessionKeyOwnershipClaimJSON)
    );
  } catch (e) {
    if (e instanceof z.ZodError) {
      throw new Error("invalid signature: claimJSON is invalid; " + e.message);
    }
    throw e;
  }

  if (ownershipClaim.user_id !== blockSession.user_id) {
    throw new Error(
      `invalid signature: user id mismatch; ${ownershipClaim.user_id}, ${blockSession.user_id}`
    );
  }

  if (ownershipClaim.session.id !== blockSession.id) {
    throw new Error(
      `invalid signature: session.id mismatch; ${ownershipClaim.session.id}, ${blockSession.id}`
    );
  }

  // FIXME(jerome): check that keyId is a valid (and active) keyId for this user
  if (ownershipClaim.certifyingKeyId !== blockSession.persistentPublicKeyId) {
    throw new Error(
      `invalid signature: certifyingKeyId mismatch; ${ownershipClaim.certifyingKeyId}, ${blockSession.persistentPublicKeyId}`
    );
  }

  if (ownershipClaim.twitter_username !== user.twitter_username) {
    throw new Error(
      `invalid signature: twitter mismatch; ${ownershipClaim.twitter_username}, ${user.twitter_username}`
    );
  }

  if (ownershipClaim.session.start !== blockSession.initDate) {
    throw new Error(
      `invalid signature: session.start mismatch; ${ownershipClaim.session.start}, ${blockSession.initDate}`
    );
  }

  if (ownershipClaim.session.expires !== blockSession.expirationDate) {
    throw new Error(
      `invalid signature: session.expires mismatch; ${ownershipClaim.session.expires}, ${blockSession.expirationDate}`
    );
  }

  // 2. Claim is properly formed,
  // verify claimJSON signature with session public key
  if (
    !(await ecdsaP256VerifyMessage(
      new TextEncoder().encode(sessionKeyOwnershipClaimJSON),
      b64UrlToUint8Array(publicOwnershipProof.sessionKeyProof.signature),
      blockSessionPublicKey
    ))
  ) {
    throw new Error(
      "invalid signature: claimJSON has not been signed properly"
    );
  }
  console.log("claimJSON signature is valid");

  // 3. Session Key claim is verified
  // Verify that the persistentKeyCertificate challenge corresponds to the session key signature of the session claim JSON
  const persistentKeyCertificate =
    publicOwnershipProof.persistentKeyCertificate;

  const certClientData: { challenge: string } = JSON.parse(
    persistentKeyCertificate.clientDataJSON
  );

  if (
    certClientData.challenge !== publicOwnershipProof.sessionKeyProof.signature
  ) {
    throw new Error(
      `invalid signature: persistentKeyCertificate challenge does not match claimJSON signature; ${certClientData.challenge}, ${publicOwnershipProof.sessionKeyProof.signature}`
    );
  }

  // 4. The persistentKeyCertificate challenge is valid
  // Verify that the persistentKeyCertificate signature is valid

  if (
    !(await ecdsaP256VerifyMessage(
      concatArrayBuffers(
        b64UrlToUint8Array(
          publicOwnershipProof.persistentKeyCertificate.authenticatorData
        ),
        await sha256(
          publicOwnershipProof.persistentKeyCertificate.clientDataJSON
        )
      ),
      ecdsaP256RawSignatureFromDERSignature(
        b64UrlToUint8Array(
          publicOwnershipProof.persistentKeyCertificate.derEncodedSignature
        )
      ),
      persistentPublicKey
    ))
  ) {
    throw new Error("invalid signature: persistentKeyCertificate signature");
  }

  // 5. The persistentKeyCertificate signature is valid
  // ownership of the session key is publicly verifiable
  console.log(
    "Ownership of the session key is certified by the persistent key and is publicly verifiable"
  );
}
