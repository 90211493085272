import { createContext, Dispatch, SetStateAction } from "react";
import {
  AuthStatusResponse,
  PostBlockSessionRequestResponseBody,
  PublicKeyOwnedAPIModel,
} from "../../../common-ts/apimodel";

interface AppContextData {
  authStatus: AuthStatusResponse | null;
  setAuthStatus: Dispatch<SetStateAction<AuthStatusResponse | null>>;

  persistentPublicKey: PublicKeyOwnedAPIModel | null;
  setPersistentPublicKey: Dispatch<
    SetStateAction<PublicKeyOwnedAPIModel | null>
  >;

  blockSessionKeyPair: CryptoKeyPair | null;
  setBlockSessionKeyPair: Dispatch<SetStateAction<CryptoKeyPair | null>>;

  blockSession: PostBlockSessionRequestResponseBody | null;
  setBlockSession: Dispatch<
    SetStateAction<PostBlockSessionRequestResponseBody | null>
  >;

  // detectedBlocks: string[] /* block hashes*/;
  // setDetectedBlocks: Dispatch<SetStateAction<string[]>>;
}

const initialState: AppContextData = {
  authStatus: null,
  setAuthStatus: () => {},

  persistentPublicKey: null,
  setPersistentPublicKey: () => {},

  blockSessionKeyPair: null,
  setBlockSessionKeyPair: () => {},

  blockSession: null,
  setBlockSession: () => {},

  // detectedBlocks: [],
  // setDetectedBlocks: () => {},
};

export const AppContext = createContext<AppContextData>(initialState);
