import React from "react";
import Footer from "../Footer";
import Header from "../Header";

interface StdPageProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

export default function StdPage(props: StdPageProps) {
  return (
    <>
      {props.header ? props.header : <Header />}
      <main className="flex-grow flex items-center justify-center">
        {props.children}
      </main>
      {props.footer ? props.footer : <Footer />}
    </>
  );
}
