import React from "react";
import MainLink from "./MainLink";
import MainHeading from "./MainHeading";
import MainButton from "./MainButton";
import MainContainer from "./MainContainer";
import InputText from "./InputText";
import Button from "./Button";

type PageContentProps = {
  title: string|null;
  children?: React.ReactNode;
};

export default function PageContent(props: PageContentProps) {
  return (
    <MainContainer>
      {props.title ? <MainHeading>{props.title}</MainHeading> : null}
      {props.children}
    </MainContainer>
  );
}

PageContent.MainLink = MainLink;
PageContent.MainHeading = MainHeading;
PageContent.MainButton = MainButton;
PageContent.MainContainer = MainContainer;
PageContent.InputText = InputText;
PageContent.Button = Button;
