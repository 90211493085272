import { createRoot } from "react-dom/client";
import App from "./components/App";

(async function main() {
  // Check if the browser supports WebAuthn
  if (!navigator.credentials) {
    alert(
      "Your system does not support WebAuthn, but it is required to use Genueen."
    );
    return;
  }

  const root = createRoot(document.getElementById("root")!);
  root.render(App());
})();
