import React from "react";
import { verifyBlock } from "../verifyBlock";
import PageContent from "./PageContent";

export default function VerifyBlock() {
  const [blockId, setBlockId] = React.useState("");
  const [blockIdLooksOk, setBlockIdLooksOk] = React.useState(false);

  React.useEffect(() => {
    setBlockIdLooksOk(blockId.length === 32 && /^[0-9a-fA-F]+$/.test(blockId));
  }, [blockId]);

  function handleVerifyBlock() {
    if (!blockId) {
      alert("Please enter a block signature");
      return;
    }

    (async () => {
      try {
        await verifyBlock(blockId);
        alert("Block is valid");
      } catch (e) {
        alert(e);
      }
    })();
  }

  const buttonIsDisabled = !blockIdLooksOk;

  return (
    <PageContent title="Verify a block.">
      <PageContent.InputText
        placeholder="Your block signature here"
        value={blockId}
        onChange={setBlockId}
      />

      <PageContent.MainButton
        onClick={handleVerifyBlock}
        disabled={buttonIsDisabled}
      >
        Verify Block
      </PageContent.MainButton>
    </PageContent>
  );
}
