import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import apiURL from "../apiroute";
import logInWithTwitterConfirm from "../logInWithTwitterConfirm";
import {
  AuthStatusResponse,
  AuthTokenJWTPayload,
} from "../../../common-ts/apimodel";
import { AppContext } from "./AppContext";

function TwitterOAuth() {
  const { setAuthStatus } = React.useContext(AppContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchAuthStatus = async () => {
      // get auth info from API (we cannot see cookie from JS as it is marked as HttpOnly for safety)
      const response = await fetch(apiURL("/auth-status"), {
        credentials: "include",
      });
      const authStatusData: AuthStatusResponse = await response.json();
      setAuthStatus(authStatusData);
    };

    fetchAuthStatus();
  }, []);

  React.useEffect(() => {
    const url = new URL(document.location.href);
    const oauthToken = url.searchParams.get("oauth_token");
    const oauthVerifier = url.searchParams.get("oauth_verifier");

    if (oauthToken !== null && oauthVerifier !== null) {
      logInWithTwitterConfirm(oauthToken, oauthVerifier)
        .then((jwtPayload: AuthTokenJWTPayload) => {
          setAuthStatus({ status: "auth", data: jwtPayload });
          // jwt authToken cookie for www.genueen.com/api has been set;
          // refresh without params in URL
          navigate("/");
        })
        .catch((error) => {
          console.error("Error during logInWithTwitterConfirm:", error);
        });
    }
  }, [navigate]);

  return (<></>);
}

export default TwitterOAuth;
