import jsQR from "jsqr";
import { uint8ArrayToLCHex } from "../../common-ts/bytes";

export function qrCodeDetection(
  video: HTMLVideoElement,
  canvas: HTMLCanvasElement,
  onDetectedBlock: (blockHash: string) => void
): void {
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  video.play();
  detectQRCode(video, canvas, onDetectedBlock, "", "");
}

function detectQRCode(
  video: HTMLVideoElement,
  canvas: HTMLCanvasElement,
  onDetectedBlock: (blockHash: string) => void,
  last: string,
  hash: string
): void {
  canvas.style.maxWidth = "100%";
  canvas.style.maxHeight = "400px";
  const ctx = canvas.getContext("2d", {
    willReadFrequently: true,
  }) as CanvasRenderingContext2D;
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const code = jsQR(imageData.data, imageData.width, imageData.height, {
    inversionAttempts: "invertFirst",
  });

  // ctx.putImageData(imageData, 0, 0);

  if (code) {
    ctx.beginPath();
    ctx.moveTo(code.location.topLeftCorner.x, code.location.topLeftCorner.y);
    ctx.lineTo(code.location.topRightCorner.x, code.location.topRightCorner.y);
    ctx.lineTo(
      code.location.bottomRightCorner.x,
      code.location.bottomRightCorner.y
    );
    ctx.lineTo(
      code.location.bottomLeftCorner.x,
      code.location.bottomLeftCorner.y
    );
    ctx.closePath();
    ctx.strokeStyle = "red";
    ctx.lineWidth = 5;
    ctx.stroke();

    const hash = uint8ArrayToLCHex(new Uint8Array(code.binaryData));
    if (last !== hash) {
      last = hash;
      if (hash.length === 32) {
        onDetectedBlock(hash);
      } else {
        console.error(
          `ERROR: QR Code Detection: Invalid length for hash "${hash}"`
        );
      }

      // const frameIndex = data[0];
      // if (frameIndex === "1") {
      //   hash = "";
      // }

      // // FIXME(jerome): add validation of the frame index (should be prev + 1 or 1 if first frame)

      // hash += data.slice(1);

      // if (hash.length == 64) {
      //   onDetectedBlock(hash);
      //   hash = "";
      // } else if (hash.length > 64) {
      //   hash = "";
      //   console.error(`ERROR: QR Code Detection: Hash too long: ${hash}`);
      // }
    }
  }

  requestAnimationFrame(() =>
    detectQRCode(video, canvas, onDetectedBlock, last, hash)
  );
}
