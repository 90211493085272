export async function signOnYubikey(
  persistentKeyPairId: ArrayBufferLike,
  challenge: ArrayBuffer
): Promise<{
  authenticatorData: ArrayBuffer;
  clientDataJSON: ArrayBuffer;
  derEncodedSignature: ArrayBuffer;
}> {
  const req: CredentialRequestOptions = {
    publicKey: {
      challenge,
      allowCredentials: [
        {
          type: "public-key",
          id: persistentKeyPairId,
          transports: ["usb", "ble", "nfc", "internal"],
        },
      ],
      userVerification: "preferred",
    },
  };

  const assertion: any = await navigator.credentials.get(req);
  if (assertion === null) {
    throw new Error("No assertion");
  }

  console.log("signOnYubiKey assertion", assertion);

  const response = (assertion as any).response;

  return {
    authenticatorData: response.authenticatorData,
    clientDataJSON: response.clientDataJSON,
    derEncodedSignature: response.signature,
  };
}

export async function createPersistentECDSAP256KeyPairOnYubikey(
  challenge: ArrayBuffer
): Promise<{
  attestationObject: ArrayBuffer;
  clientDataJSON: ArrayBuffer;
  persistentKeyId: string;
}> {
  let credential: any;
  try {
    // see https://developers.yubico.com/WebAuthn/WebAuthn_Developer_Guide/WebAuthn_Client_Registration.html
    credential = await navigator.credentials.create({
      publicKey: {
        attestation: "direct",
        authenticatorSelection: {
          authenticatorAttachment: "cross-platform",
          requireResidentKey: false,
          userVerification: "preferred",
        },
        challenge,
        excludeCredentials: [],
        pubKeyCredParams: [
          {
            type: "public-key",
            alg: -7, // ECDSA P-256 (secp256r1) with SHA-256
          },
        ],
        rp: {
          name: "Genueen",
          // id: "www.genueen.com",
        },
        timeout: 30000,
        user: {
          // FIXME(jerome): figure this out
          id: new Uint8Array(32),
          name: "user@example.com",
          displayName: "User Display Name",
        },
      },
    });
  } catch (error) {
    throw new Error("Could not create credentials: " + error);
  }

  if (credential === null) {
    throw new Error("Credential is null");
  }

  console.log("PERSISTENT credential response", credential);

  return {
    attestationObject: credential.response.attestationObject,
    clientDataJSON: credential.response.clientDataJSON,
    persistentKeyId: credential.id,
  };
}
