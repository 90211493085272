import React from "react";

interface InputTextProps {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
}

export default function InputText(props: InputTextProps) {
  return (
    <input
      type="text"
      className="mt-6 bg-gray-800 text-white placeholder-gray-500 font-semibold px-4 py-2 rounded shadow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 w-full text-sm"
      style={{mixBlendMode: "luminosity"}}
      placeholder={props.placeholder}
      value={props.value}
      onChange={
        props.onChange !== undefined
          ? (e) => (props.onChange as any)(e.target.value)
          : undefined
      }
    />
  );
}
