import React from "react";

type MainContainerProps = {
  children: React.ReactNode;
};

export default function MainContainer(props: MainContainerProps) {
  return (
    <div className="bg-gray-900 px-6 py-24 sm:py-32 lg:px-8 w-full">
      <div className="mx-auto max-w-2xl text-left pb-12 mb-12">{props.children}</div>
    </div>
  );
}
