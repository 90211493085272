import React from "react";
import { Link } from "react-router-dom";
import AuthStatus from "../AuthStatus";

interface HeaderProps {
  children?: React.ReactNode;
}

export default function Header(props: HeaderProps) {
  return (
    <header className="bg-gray-800 py-4 px-6">
      <div className="flex justify-between items-center">
        <Link to="/" className="text-xl font-semibold text-orange-500">
          Genueen
        </Link>
        {props.children ? (
          <div className="flex-grow text-center">{props.children}</div>
        ) : null}
        <nav>
          <AuthStatus />
        </nav>
      </div>
    </header>
  );
}
