import React from "react";
import Button from "./Button";

type MainButtonProps = {
  children: React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
};

export default function MainButton(props: MainButtonProps) {
  return <Button addStyle="mt-9" {...props} />;
}
