import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { AppContext } from "./AppContext";
import { AuthStatusResponseAuth } from "../../../common-ts/apimodel";
import { startRecording } from "../audio";
import CreateSessionAndStartRecording from "./CreateSessionAndStartRecording";
import PageContent from "./PageContent";
import StdPage from "./StdPage";
import Header from "./Header";
import NeedsAuth from "./NeedsAuth";
import Footer from "./Footer";

type StopRecordingFunction = () => Promise<void>;

export default function CaptureLivePage() {
  const { authStatus, blockSession } = useContext(AppContext);
  const [blockSessionName, setBlockSessionName] = React.useState<
    string | undefined
  >();

  const [stopRecording, setStopRecording]: [
    StopRecordingFunction | null,
    Dispatch<SetStateAction<StopRecordingFunction | null>>
  ] = useState<StopRecordingFunction | null>(null);

  function handleStartRecording(
    blockSessionKeyPair: CryptoKeyPair,
    blockSession: any
  ) {
    const authData = (authStatus as AuthStatusResponseAuth).data;

    (async () => {
      const stopRecordingFunction = await startRecording(
        authData.user.id,
        blockSessionKeyPair,
        blockSession.sessionId,
        blockSession.blockSequenceSeed
      );
      setStopRecording(() => stopRecordingFunction);
    })();
  }

  function handleStopRecording() {
    if (stopRecording) {
      (async () => {
        await stopRecording();
        setStopRecording(null);
      })();
    }
  }

  return (
    <StdPage
      header={
        <Header>
          {stopRecording ? (
            <>
              <span className="text-red-500 animate-blink">&#x1F534;</span>
              <a
                className="text-orange-500 hover:text-orange-400 cursor-pointer font-bold"
                onClick={handleStopRecording}
              >
                Stop Recording
              </a>
            </>
          ) : null}
          {blockSession ? (
            <span className="text-gray-500">
              {" "}
              Session {blockSession.sessionId}
            </span>
          ) : null}
        </Header>
      }
      footer={stopRecording ? <></> : <Footer />}
    >
      <NeedsAuth>
        <div
          className={`bg-gray-900 px-6 lg:px-8 w-full ${
            stopRecording ? "py-2" : "py-24 sm:py-32"
          }`}
        >
          <div
            className={`mx-auto max-w-2xl text-left ${
              stopRecording ? "" : "pb-12 mb-12"
            }`}
          >
            {stopRecording ? null : (
              <>
                <PageContent.MainHeading>Capture Live.</PageContent.MainHeading>
                <PageContent.InputText
                  placeholder="Optional Session Name"
                  onChange={(value) => {
                    if (value.trim() !== "") {
                      setBlockSessionName(value);
                    } else {
                      setBlockSessionName(undefined);
                    }
                  }}
                  value={blockSessionName}
                />
              </>
            )}
            <>
              {stopRecording ? (
                <canvas id="canvas"></canvas>
              ) : (
                <CreateSessionAndStartRecording
                  blockSessionName={blockSessionName}
                  startRecording={handleStartRecording}
                />
              )}
            </>
          </div>
        </div>
      </NeedsAuth>
    </StdPage>
  );
}
