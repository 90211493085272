import React, { useContext, useEffect, useRef, useState } from "react";
import { qrCodeDetection } from "../qrCodeDetection";
import { AppContext } from "./AppContext";
import PageContent from "./PageContent";
import apiURL from "../apiroute";
import { verifyBlock } from "../verifyBlock";
import {
  BlockDetails,
  BlockSessionAPIModel,
  UserAPIModel,
} from "../../../common-ts/apimodel";
import GrayTwitterIcon from "./GrayTwitterIcon";
import { Link } from "react-router-dom";

export default function VerifyVideo() {
  const [file, setFile] = useState<File | null>(null);
  const [videoURL, setVideoURL] = useState<string | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [detectedBlockIds, setDetectedBlockIds] = useState<string[]>([]);
  const [blockVerificationStatuses, setBlockVerificationStatuses] =
    React.useState<
      Record<
        string, // block ID
        {
          session?: BlockSessionAPIModel;
          block?: BlockDetails;
          user?: UserAPIModel;
          status: "verified" | "error";
          error?: string;
        }
      >
    >({});

  useEffect(() => {
    if (file) {
      const fileReader = new FileReader();

      fileReader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target && e.target.result) {
          const objectUrl = URL.createObjectURL(
            new Blob([e.target.result as ArrayBuffer], { type: file.type })
          );

          setVideoURL(objectUrl);
        }
      };

      fileReader.readAsArrayBuffer(file);
    }
  }, [file]);

  async function verifyDetectedBlock(blockId: string) {
    try {
      const { user, session, block } = await verifyBlock(blockId);
      blockVerificationStatuses[blockId] = {
        status: "verified",
        session: session,
        block: block,
        user: user,
      };
      setBlockVerificationStatuses({ ...blockVerificationStatuses });
    } catch (e) {
      blockVerificationStatuses[blockId] = {
        status: "error",
        error: e.message,
      };
      setBlockVerificationStatuses({ ...blockVerificationStatuses });
    }
  }

  function handleOnLoadedMetadata(this: HTMLVideoElement) {
    qrCodeDetection(
      videoRef.current!,
      canvasRef.current!,
      (blockId: string) => {
        detectedBlockIds.push(blockId);
        setDetectedBlockIds([...detectedBlockIds]);
        setTimeout(() => verifyDetectedBlock(blockId));
      }
    );
  }

  return (
    <PageContent title="Verify a video.">
      <p className="mt-6">
        <input
          type="file"
          accept="video/*"
          onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
        />
        {videoURL && (
          <>
            <video
              style={{ display: "none" }}
              ref={videoRef}
              controls={true}
              src={videoURL}
              onLoadedMetadata={handleOnLoadedMetadata}
            ></video>
            <canvas ref={canvasRef}></canvas>
          </>
        )}
      </p>

      {detectedBlockIds.length > 0 && (
        <div>
          <h3 className="text-3xl font-semibold tracking-tighter text-gray-300 sm:text-4xl mt-8">
            Detected Blocks
          </h3>
          <ul>
            {detectedBlockIds.map((blockId, index) => (
              <DetectedBlock
                key={index}
                blockId={blockId}
                blockVerificationStatuses={blockVerificationStatuses}
              />
            ))}
          </ul>
        </div>
      )}
    </PageContent>
  );
}

interface DetectedBlockProps {
  blockId: string;
  blockVerificationStatuses: Record<
    string,
    {
      session?: BlockSessionAPIModel;
      block?: BlockDetails;
      user?: UserAPIModel;
      status: "verified" | "error";
      error?: string;
    }
  >;
}

function DetectedBlock({
  blockId,
  blockVerificationStatuses,
}: DetectedBlockProps) {
  const verificationstatus =
    blockId in blockVerificationStatuses
      ? blockVerificationStatuses[blockId]
      : undefined;
  const block: BlockDetails | undefined = verificationstatus?.block;
  const blockJSONParsed = block ? JSON.parse(block.blockJSON) : undefined;
  const session: BlockSessionAPIModel | undefined = verificationstatus?.session;
  const user: UserAPIModel | undefined = verificationstatus?.user;

  return (
    <li className="mt-2 rounded-md bg-gray-800 p-5">
      <p>
        <span className="text-orange-600 font-bold">ID: </span>
        <span className="whitespace-pre font-mono inline">{blockId}</span>{" "}
      </p>
      <p>
        {" "}
        {!verificationstatus ? (
          "verifiying..."
        ) : verificationstatus.status === "verified" ? (
          <>
            ✅ signed by <GrayTwitterIcon />
            &nbsp;{user?.twitter_username}{" "}
            on {new Date(blockJSONParsed.date).toUTCString()}
          </>
        ) : (
          <span className="text-red-500">
            ❌ Verification failed: {verificationstatus.error}
          </span>
        )}
      </p>
      {!!session && !!blockJSONParsed && (
        <p>
          <span className="text-orange-600 font-bold">
            Block {blockJSONParsed.numInSession} in Session
          </span>{" "}
          <Link to={`/verify/session/${session.id}`}>
            {session?.unsigned?.name || "Unnamed Session"}
          </Link>
        </p>
      )}
      {verificationstatus?.status === "verified" && (
        <p className="mt-3 text-center">
          <audio src={apiURL(`/block/${blockId}/data`)} controls className="mx-auto"></audio>
        </p>
      )}
    </li>
  );
}
