export function uint8ArrayToB64Url(arr: Uint8Array): string {
  return uint8ArrayToB64(arr)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

export function uint8ArrayToB64(arr: Uint8Array): string {
  return btoa(String.fromCharCode(...arr));
}

export function b64ToUint8Array(b64: string): Uint8Array {
  return new Uint8Array(
    atob(b64)
      .split("")
      .map((c) => c.charCodeAt(0))
  );
}

export function stringToB64(str: string): string {
  return btoa(str);
}

export function b64UrlToString(b64UrlSafe: string): string {
  const base64 = b64UrlSafe.replace(/-/g, "+").replace(/_/g, "/");
  const padding = "=".repeat((4 - (base64.length % 4)) % 4);
  const base64WithPadding = base64 + padding;
  return atob(base64WithPadding);
}

export function b64UrlToUint8Array(b64UrlSafe: string): Uint8Array {
  const base64 = b64UrlSafe.replace(/-/g, "+").replace(/_/g, "/");
  const padding = "=".repeat((4 - (base64.length % 4)) % 4);
  const base64WithPadding = base64 + padding;
  return b64ToUint8Array(base64WithPadding);
}

export function encodeArrayBufferAsBase64(arrayBuffer: ArrayBuffer) {
  const bytes = new Uint8Array(arrayBuffer);
  const binary = Array.from(bytes)
    .map((byte) => String.fromCharCode(byte))
    .join("");
  return btoa(binary);
}

export function encodeArrayBufferAsBase64Url(arrayBuffer: ArrayBuffer) {
  return encodeArrayBufferAsBase64(arrayBuffer)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
}

export function decodeBase64AsArrayBuffer(base64: string): ArrayBuffer {
  return Uint8Array.from(atob(base64), (c) => c.charCodeAt(0)).buffer;
}

export function uint8ArrayToLCHex(arr: Uint8Array): string {
  return Array.from(arr)
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
}

export function hexToUintArray(hex: string): Uint8Array {
  return new Uint8Array(
    hex.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16))
  );
}

export function singleU8ToBeBytes(u8: number): Uint8Array {
  return new Uint8Array(new Uint8Array([u8]));
}

export function uuidStrAsBytes(uuidStr: string): Uint8Array {
  return hexToUintArray(uuidStr.replace(/-/g, ""));
}

export function singleF64ToBeBytes(f64: number) {
  return new Uint8Array(
    new Uint8Array(new Float64Array([f64]).buffer).reverse().buffer
  );
}

export function singleU32ToBeBytes(u32: number) {
  return new Uint8Array(
    new Uint8Array(new Uint32Array([u32]).buffer).reverse().buffer
  );
}

export function arrayU8ToBeBytes(arr: Uint8Array) {
  return new Uint8Array(arr);
}

export function stringToBeBytes(str: string) {
  return new TextEncoder().encode(str);
}

export function makeFakeUUIDAsBytes(): Uint8Array {
  const bytes = new Uint8Array(16);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = Math.floor(Math.random() * 256);
  }
  return bytes;
}

export function concatArrayBuffers(...buffers: ArrayBufferLike[]): ArrayBuffer {
  const totalLength = buffers.reduce(
    (acc, buffer) => acc + buffer.byteLength,
    0
  );
  const result = new Uint8Array(totalLength);
  let offset = 0;
  for (const buffer of buffers) {
    result.set(new Uint8Array(buffer), offset);
    offset += buffer.byteLength;
  }
  return result.buffer;
}

// avoid reading bytes outside of sliced view
export function copyUint8Array(src: Uint8Array) {
  var dst = new ArrayBuffer(src.byteLength);
  new Uint8Array(dst).set(src);
  return new Uint8Array(dst);
}

export function copyArrayBufferSlice(src: ArrayBuffer) {
  var dst = new ArrayBuffer(src.byteLength);
  new Uint8Array(dst).set(new Uint8Array(src));
  return dst;
}
