import React from "react";
import {
  BlockHandle,
  BlockSessionAPIModel,
  BlockSessionKeyOwnershipClaim,
  PublicKeyPublicAPIModel,
  UserAPIModel,
} from "../../../../common-ts/apimodel";
import GrayTwitterIcon from "../GrayTwitterIcon";
import { Link } from "react-router-dom";
import { assertBlockSessionKeyPublicOwnershipProof } from "../../../../common-ts/assertBlockSessionKeyPublicOwnershipProof";
import {
  ecdsaP256ConvertPublicKeyToRaw,
  ecdsaP256ImportPublicKeyRaw,
} from "../../../../common-ts/crypto";
import { b64ToUint8Array } from "../../../../common-ts/bytes";
import apiURL from "../../apiroute";
import Button from "../PageContent/Button";

interface BlockSessionDetailsProps {
  session: BlockSessionAPIModel;
  onVerifyClick: (blockHandles: BlockHandle[]) => void;
  onPlayClick: (blockHandles: BlockHandle[]) => void;
}

export default function BlockSessionDetails({
  session,
  onVerifyClick,
  onPlayClick,
}: BlockSessionDetailsProps) {
  const [user, setUser] = React.useState<UserAPIModel>();

  const [persistentPublicKeyInfo, setPersistentPublicKeyInfo] =
    React.useState<PublicKeyPublicAPIModel>();

  const [sessionValidCertification, setSessionValidCertification] =
    React.useState<boolean>();

  const [sessionBlockHandles, setSessionBlocksHandles] =
    React.useState<BlockHandle[]>();

  React.useEffect(() => {
    (async () => {
      setSessionValidCertification(undefined);
      setSessionBlocksHandles(undefined);

      // fetch user and persistent public key
      const user: UserAPIModel | undefined = await (
        await fetch(apiURL("/user/" + session.user_id))
      ).json();
      setUser(user!);

      const persistentPublicKeyInfo: PublicKeyPublicAPIModel | undefined =
        await (
          await fetch(apiURL("/public-key/" + session.persistentPublicKeyId))
        ).json();
      setPersistentPublicKeyInfo(persistentPublicKeyInfo!);

      const blockHandles: BlockHandle[] | undefined = await (
        await fetch(apiURL("/block-session/" + session.id + "/blocks"))
      ).json();

      setSessionBlocksHandles(blockHandles);
    })();
  }, [session]);

  React.useEffect(() => {
    (async function () {
      if (!user || !persistentPublicKeyInfo) return;

      try {
        await assertBlockSessionKeyPublicOwnershipProof(
          session.publicOwnershipProof,
          session,
          user,
          await ecdsaP256ImportPublicKeyRaw(
            b64ToUint8Array(session.sessionPublicKeyB64)
          ),
          // persistent pubkey
          await ecdsaP256ImportPublicKeyRaw(
            await ecdsaP256ConvertPublicKeyToRaw(
              b64ToUint8Array(persistentPublicKeyInfo.pubkeyB64)
            )
          )
        );
        setSessionValidCertification(true);
      } catch (e) {
        console.error(e);
        setSessionValidCertification(false);
      }
    })();
  }, [user, persistentPublicKeyInfo]);

  const ownershipClaim: BlockSessionKeyOwnershipClaim = JSON.parse(
    session.publicOwnershipProof.sessionKeyProof.claimJSON
  );

  const nbBlocks = sessionBlockHandles?.length || 0;
  const durationSeconds = nbBlocks * 6;

  // const isExpired = session.expirationDate < new Date().toISOString();

  return (
    <>
      {/* FIXME(jerome): it needs to be extra clear that the name is user input and not UI */}
      <h3 className="text-3xl font-semibold tracking-tighter text-gray-300 sm:text-4xl mt-8">
        {session.unsigned?.name && <p><span className="text-orange-500">Name: </span>{session.unsigned?.name}</p>}
      </h3>

      <p className="mt-3">
        Audio session, started {new Date(session.commitDate).toUTCString()}
        {sessionBlockHandles && (
          <>
            ; {sessionBlockHandles?.length} blocks, {durationSeconds} seconds
          </>
        )}
      </p>
      <p className="mt-3">
        {sessionValidCertification === undefined ? (
          <>Verifying session certification...</>
        ) : sessionValidCertification ? (
          <>✅ </>
        ) : (
          <span className="text-red-500">❌ Verification failed</span>
        )}
        {sessionValidCertification === true ? (
          <>
            <span className="font-bold">Certified by{" "}
            <a
              href={`https://twitter.com/${ownershipClaim.twitter_username}`}
              target="_blank"
            >
              <GrayTwitterIcon /> {ownershipClaim.twitter_username}
            </a>
            </span>
            , using key{" "}
            <Link to={`/key/${ownershipClaim.certifyingKeyId}`}>
              {ownershipClaim.certifyingKeyId}
            </Link>
          </>
        ) : null}
      </p>
      {sessionBlockHandles ? (
        <>
          <p className="mt-7">
            <Button
              onClick={() => onPlayClick(sessionBlockHandles)}
              addStyle="mr-5"
            >
              Play and verify Audio Session
            </Button>
            {/* <Button onClick={() => onVerifyClick(sessionBlockHandles)}>
              Verify blocks
            </Button> */}
          </p>
        </>
      ) : null}
    </>
  );
}
