import {
  ecdsaP256ConvertPublicKeyToRaw,
  ecdsaP256ImportPublicKeyRaw,
  sha256,
  sha256LCHexString,
  ecdsaP256VerifyMessage,
} from "../../common-ts/crypto";
import { b64ToUint8Array } from "../../common-ts/bytes";
import { assertBlockSessionKeyPublicOwnershipProof } from "../../common-ts/assertBlockSessionKeyPublicOwnershipProof";

import {
  AudioBlock,
  AudioBlockSchema,
  BlockDetails,
  BlockDetailsSchema,
  BlockSessionAPIModel,
  PublicKeyPublicAPIModel,
  UserAPIModel,
} from "../../common-ts/apimodel";
import apiURL from "./apiroute";

export async function verifyBlock(blockId: string): Promise<{
  block: BlockDetails;
  session: BlockSessionAPIModel;
  user: UserAPIModel;
}> {
  const json: any = await (
    await fetch(apiURL("/block/" + blockId), {
      credentials: "include", // send JWT cookie "authToken"
    })
  ).json();

  console.log("block details", json);

  // 0. Verify block structure
  const block: BlockDetails = BlockDetailsSchema.parse(json);
  const audioBlock: AudioBlock = AudioBlockSchema.parse(
    JSON.parse(block.blockJSON)
  );

  // 1. verify that id is sha256 of signature
  const ourBlockId = (
    await sha256LCHexString(b64ToUint8Array(block.signature))
  ).slice(0, 32);
  if (block.id !== ourBlockId) {
    throw new Error("Block ID does not match signature");
  }

  // 2. verify block data hash with hash of data
  const data = await (
    await fetch(apiURL("/block/" + blockId + "/data"), {
      credentials: "include", // send JWT cookie "authToken"
    })
  ).arrayBuffer();
  if (audioBlock.body.dataHash !== (await sha256LCHexString(data))) {
    console.log(b64ToUint8Array(audioBlock.body.dataHash), await sha256(data));
    throw new Error("Block data hash does not match data");
  }

  // 3. verify block signature with session public key
  // 3.a; get session
  const session: BlockSessionAPIModel = await (
    await fetch(apiURL("/block-session/" + audioBlock.sessionId), {
      credentials: "include", // send JWT cookie "authToken"
    })
  ).json();
  console.log("Block SESSION", session);

  // 3.b; verify that signature is sha256 of blockJSON
  const sessionPublicKey: CryptoKey = await ecdsaP256ImportPublicKeyRaw(
    b64ToUint8Array(session.sessionPublicKeyB64 as string)
  );

  const blockHash = await sha256(block.blockJSON);
  if (
    !(await ecdsaP256VerifyMessage(
      blockHash,
      b64ToUint8Array(block.signature),
      sessionPublicKey
    ))
  ) {
    throw new Error("Block signature does not match session public key");
  }
  console.log("Block signature verified with session public key");

  // 4. verify that the session public key is certified by persistent public key (session.publicOwnershipProof)
  // 4.a Verify session claims are valid

  // get user
  const user: UserAPIModel | null = await (
    await fetch(apiURL("/user/" + session.user_id))
  ).json();

  if (!user) {
    throw new Error("User not found");
  }

  // get persistent public key
  const persistentPublicKeyInfo: PublicKeyPublicAPIModel | null = await (
    await fetch(apiURL("/public-key/" + session.persistentPublicKeyId))
  ).json();

  if (!persistentPublicKeyInfo) {
    throw new Error("Persistent public key not found");
  }

  await assertBlockSessionKeyPublicOwnershipProof(
    session.publicOwnershipProof,
    session,
    user,
    await ecdsaP256ImportPublicKeyRaw(
      b64ToUint8Array(session.sessionPublicKeyB64)
    ),
    // persistent pubkey
    await ecdsaP256ImportPublicKeyRaw(
      await ecdsaP256ConvertPublicKeyToRaw(
        b64ToUint8Array(persistentPublicKeyInfo.pubkeyB64)
      )
    )
  );

  //  5. verify ownership of public key (ownershipProof, challenge=user_id; see api's code, verifyPersistentKeyOwnership)
  // FIXME(jerome): implement

  return { user, session, block };
}
