import { z } from "zod";

export const BlockHandleSchema = z
  .object({
    id: z.string().nonempty(),
    sessionId: z.string().nonempty(),
    blockDate: z.string().nonempty(),
  })
  .strict();

export type BlockHandle = z.infer<typeof BlockHandleSchema>;

export const BlockDetailsSchema = z
  .object({
    id: z.string().nonempty(),
    apiVersion: z.string().nonempty(),
    signature: z.string().nonempty(),
    blockJSON: z.string().nonempty(),
  })
  .strict();

export type BlockDetails = z.infer<typeof BlockDetailsSchema>;

export const AudioBlockSchema = z
  .object({
    type: z.literal("audio"),
    client: z.string().nonempty(),
    user_id: z.string().nonempty(),
    numInSession: z.number(),
    randomSequence: z.string().nonempty(),
    sessionId: z.string().nonempty(),
    date: z.string().nonempty(),
    body: z
      .object({
        dataHash: z.string().nonempty(),
        begin: z.string().nonempty(),
        end: z.string().nonempty(),
      })
      .strict(),
  })
  .strict();

export type AudioBlock = z.infer<typeof AudioBlockSchema>;

export const PostPublicKeyRequestResponseBodySchema = z
  .object({
    details: z
      .object({
        keyId: z.string().nonempty(),
        challenge: z.string().nonempty(),
      })
      .strict(),
    derEncodedSignature: z.string().nonempty(),
  })
  .strict();

export type PostPublicKeyRequestResponseBody = z.infer<
  typeof PostPublicKeyRequestResponseBodySchema
>;

export const WebauthnSignatureDataSchema = z
  .object({
    clientDataJSON: z.string().nonempty(),
    authenticatorData: z.string().nonempty(),
    derEncodedSignature: z.string().nonempty(),
  })
  .strict();

export const PostPublicKeyCommitRequestBodySchema = z
  .object({
    init: PostPublicKeyRequestResponseBodySchema,
    algorithm: z.literal("ecdsa-p256"),
    yubikeyKeyIdB64: z.string().nonempty(),
    persistentKeyProof: z
      .object({
        clientDataJSON: z.string().nonempty(), // b64
        attestationObject: z.string().nonempty(), // b64
      })
      .strict(),

    // for 3rd parties (ie, not us, the WebAuthn Relying Party)
    publicOwnershipProof: WebauthnSignatureDataSchema,
  })
  .strict();

export type PostPublicKeyCommitRequestBody = z.infer<
  typeof PostPublicKeyCommitRequestBodySchema
>;

export const PersistentKeyOwnershipClaimSchema = z
  .object({
    topic: z.literal("genueen.com:persistent_key_ownership_claim"),
    keyId: z.string().nonempty(),
    user_id: z.string().nonempty(),
    twitter_username: z.string().nonempty(),
  })
  .strict();

export type PersistentKeyOwnershipClaim = z.infer<
  typeof PersistentKeyOwnershipClaimSchema
>;

export const BlockSessionKeyPublicOwnershipProofSchema = z.object({
  sessionKeyProof: z
    .object({
      claimJSON: z.string().nonempty(),
      signature: z.string().nonempty(),
    })
    .strict(),
  persistentKeyCertificate: WebauthnSignatureDataSchema,
});

export type BlockSessionKeyPublicOwnershipProof = z.infer<
  typeof BlockSessionKeyPublicOwnershipProofSchema
>;

export const BlockSessionKeyOwnershipClaimSchema = z
  .object({
    topic: z.literal("genueen.com:session_key_ownership_claim"),
    certifyingKeyId: z.string().nonempty(),
    user_id: z.string().nonempty(),
    twitter_username: z.string().nonempty(),
    session: z
      .object({
        id: z.string().nonempty(),
        start: z.string().nonempty(),
        expires: z.string().nonempty(),
      })
      .strict(),
  })
  .strict();

export type BlockSessionKeyOwnershipClaim = z.infer<
  typeof BlockSessionKeyOwnershipClaimSchema
>;

export type PublicKeyPublicAPIModel = {
  id: string;
  algorithm: string;
  createdAt: string;
  user_id: string;
  pubkeyB64: string;
  publicOwnershipProofJSON: string;
};

export type PublicKeyOwnedAPIModel = PublicKeyPublicAPIModel & {
  yubikeyKeyIdB64: string;
};

export function publicKeyOwnedAPIModelToPublic(
  owned: PublicKeyOwnedAPIModel
): PublicKeyPublicAPIModel {
  const res = {
    ...owned,
    yubikeyKeyIdB64: undefined,
  };

  delete res.yubikeyKeyIdB64;
  return res;
}

export type PostBlockSessionRequestResponseBody = {
  // FIXME(jerome): we disclose yubikeyId here because the front needs it, but it's only on authenticated endpoint
  // for the user to whom the yubikey belongs
  publicKey: PublicKeyOwnedAPIModel;
  initDate: string;
  expires: string;
  sessionId: string;
  challengeB64: string;
  blockSequenceSeed: string; // 32 bytes, lowercase hex
};

export type BlockSessionAPIModel = {
  id: string;
  user_id: string;
  initDate: string;
  commitDate: string;
  expirationDate: string;
  persistentPublicKeyId: string;
  sessionPublicKeyB64: string;
  publicOwnershipProof: BlockSessionKeyPublicOwnershipProof;
  unsigned?: {
    name?: string | undefined;
    public?: boolean | undefined;
  };
};

const UserAPIModelSchema = z
  .object({
    id: z.string().nonempty(),
    created_at: z.string().nonempty(),
    twitter_name: z.string().nonempty(),
    twitter_username: z.string().nonempty(),
  })
  .strict();

export type UserAPIModel = z.infer<typeof UserAPIModelSchema>;

export const AuthTokenJWTPayloadSchema = z.object({
  user: UserAPIModelSchema,
  jti: z.string().nonempty(), // JWT ID
  exp: z.number().positive(), // Valid until
});

export type AuthTokenJWTPayload = z.infer<typeof AuthTokenJWTPayloadSchema>;

export type AuthStatusResponseAuth = {
  status: "auth";
  data: AuthTokenJWTPayload;
};

export type AuthStatusResponseUnauth = {
  status: "unauth";
};

export type AuthStatusResponse =
  | AuthStatusResponseAuth
  | AuthStatusResponseUnauth;

export const PostBlockSessionCommitSchema = z.object({
  algorithm: z.literal("ecdsa-p256"),
  sessionId: z.string().nonempty(),
  sessionKeyPublicB64: z.string().nonempty(), // provided in raw bytes format (0x04 || x || y) as handled by subtle.crypto
  sessionKeyProofB64Url: z.string().nonempty(), // session.signature signed with the session private key
  yubikeyKeyIdB64Url: z.string().nonempty(),
  // persistentKeyProof: WebauthnSignatureDataSchema,

  // Proof for 3rd parties that we own the block session private key
  // session claim signed by session private key + session claim signature signed by persistent private key;
  // for 3rd parties
  publicOwnershipProof: BlockSessionKeyPublicOwnershipProofSchema,
  unsigned: z
    .object({
      name: z.string().optional(),
      public: z.boolean().optional().default(false),
    })
    .strict()
    .optional(),
});

export type PostBlockSessionCommit = z.infer<
  typeof PostBlockSessionCommitSchema
>;
