import apiURL from "./apiroute";

export default async function logInWithTwitterInitiate() {
  const callbackUrl = encodeURIComponent(document.location.origin + "/");
  try {
    const response = await fetch(
      apiURL("/auth/twitter?callbackUrl=" + callbackUrl)
    );
    const data = await response.json();
    const authUrl = data.authUrl;
    window.location.href = authUrl;
  } catch (error) {
    alert("Error initiating login with Twitter; " + error);
  }
}
