import React from "react";
import PageContent from "./PageContent";
import { useNavigate } from "react-router-dom";

export default function LandingBrowse() {
  const [twitterUsername, setTwitterUsername] = React.useState<string>("");
  const navigate = useNavigate();

  const handleBrowseClick = () => {
    navigate(`/browse/${twitterUsername}`);
  };

  return (
    <PageContent title="Browse recordings.">
      <PageContent.InputText
        placeholder="🐦 Twitter username here."
        value={twitterUsername}
        onChange={setTwitterUsername}
      />
      <PageContent.Button
        addStyle="mt-5"
        onClick={handleBrowseClick}
        disabled={twitterUsername.trim() === ""}
      >
        Browse public recordings
      </PageContent.Button>
    </PageContent>
  );
}
