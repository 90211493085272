import React, { useContext, useEffect } from "react";
import { createPersistentKey as createPersistentKey } from "../createPersistentCredentials";
import { AppContext } from "./AppContext";
import {
  AuthStatusResponseAuth,
  PublicKeyOwnedAPIModel,
} from "../../../common-ts/apimodel";
import apiURL from "../apiroute";
import PageContent from "./PageContent";

export default function CreatePersistentKeyPair() {
  const { authStatus, persistentPublicKey, setPersistentPublicKey } =
    useContext(AppContext);
  const authData = (authStatus as AuthStatusResponseAuth).data;

  useEffect(() => {
    handleLoadPersistentKey();
  }, []);

  async function handleLoadPersistentKey() {
    const latestPublicKey: PublicKeyOwnedAPIModel = await (
      await fetch(apiURL("/public-key/latest"), {
        credentials: "include", // send JWT cookie "authToken"
      })
    ).json();

    // FIXME(jerome): validate model?
    setPersistentPublicKey(latestPublicKey);
  }

  async function handleCreatePersistentKey() {
    const publicKey = await createPersistentKey(authData);
    setPersistentPublicKey(publicKey);
  }

  const msg = persistentPublicKey ? "Renew key pair" : "Create key pair";

  return (
    <PageContent title="Manage Keys.">
      <PageContent.MainButton
        onClick={async () => await handleCreatePersistentKey()}
      >
        {msg}
      </PageContent.MainButton>
    </PageContent>
  );
}
