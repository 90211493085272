import React from "react";

export default function Footer() {
  return (
    <footer className="bg-gray-800 py-4 px-6">
      <div className="flex justify-center">
        <p className="text-gray-300">Genueen, 2023.</p>
      </div>
    </footer>
  );
}
