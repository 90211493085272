import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { AppContext } from "./AppContext";
import {
  AuthStatusResponse,
  PostBlockSessionRequestResponseBody,
  PublicKeyOwnedAPIModel,
} from "../../../common-ts/apimodel";
import CreatePersistentKeyPair from "./CreatePersistentKeyPair";
import VerifyBlock from "./VerifyBlock";
import CaptureLivePage from "./CaptureLivePage";
import VerifyVideo from "./VerifyVideo";
import VerifySession from "./VerifySession";
import LandingVerify from "./LandingVerify";
import LandingCapture from "./LandingCapture";
import PageContent from "./PageContent";
import StdPage from "./StdPage";
import NeedsAuth from "./NeedsAuth";
import TwitterOAuth from "./TwitterOAuth";
import LandingBrowse from "./LandingBrowse";
import BrowsePublicSessions from "./BrowsePublicSessions";

export default function App() {
  return <AppInner />;
}

function AppInner() {
  const [authStatus, setAuthStatus] = useState<AuthStatusResponse | null>(null);
  const [persistentPublicKey, setPersistentPublicKey] =
    useState<PublicKeyOwnedAPIModel | null>(null);
  const [blockSessionKeyPair, setBlockSessionKeyPair] =
    useState<CryptoKeyPair | null>(null);
  const [blockSession, setBlockSession] =
    useState<PostBlockSessionRequestResponseBody | null>(null);

  const [detectedBlocks, setDetectedBlocks] = useState<
    string[] /* block hashes*/
  >([]);

  return (
    <AppContext.Provider
      value={{
        authStatus,
        setAuthStatus,
        persistentPublicKey,
        setPersistentPublicKey,
        blockSessionKeyPair,
        setBlockSessionKeyPair,
        blockSession,
        setBlockSession,
        // detectedBlocks,
        // setDetectedBlocks,
      }}
    >
      <Router>
        <TwitterOAuth />
        <Routes>
          <Route
            path="/"
            element={
              <StdPage>
                <PageContent.MainContainer>
                  <nav className="mt-6 text-lg leading-8 text-gray-300">
                    <div className="mt-5">
                      <PageContent.MainHeading>
                        <Link to="/browse" className="hover:text-orange-400">
                          Browse recordings.
                        </Link>
                      </PageContent.MainHeading>
                    </div>
                    <div className="mt-12">
                      <PageContent.MainHeading>
                        <Link to="/verify" className="hover:text-orange-400">
                          Verify recordings.
                        </Link>
                      </PageContent.MainHeading>
                    </div>
                    {authStatus?.status === "auth" && (
                      <div className="mt-12">
                        <PageContent.MainHeading>
                          <Link to="/capture" className="hover:text-orange-400">
                            Capture and sign recordings.
                          </Link>
                        </PageContent.MainHeading>
                      </div>
                    )}
                  </nav>
                </PageContent.MainContainer>
              </StdPage>
            }
          />
          <Route
            path="/browse"
            element={
              <StdPage>
                <LandingBrowse />
              </StdPage>
            }
          />
          <Route
            path="/browse/:twitterUsername"
            element={
              <StdPage>
                <BrowsePublicSessions />
              </StdPage>
            }
          />
          <Route
            path="/verify"
            element={
              <StdPage>
                <LandingVerify />
              </StdPage>
            }
          />
          <Route
            path="/verify/block"
            element={
              <StdPage>
                <VerifyBlock />
              </StdPage>
            }
          />
          <Route
            path="/verify/video"
            element={
              <StdPage>
                <VerifyVideo />
              </StdPage>
            }
          />
          {/* 2 routes,, one to match without params, and one to match with params */}
          <Route
            path="/verify/session"
            element={
              <StdPage>
                <VerifySession />
              </StdPage>
            }
          />
          <Route
            path="/verify/session/:sessionId"
            element={
              <StdPage>
                <VerifySession />
              </StdPage>
            }
          />
          <Route
            path="/key/:keyId"
            element={
              <StdPage>
                <h2>Key detail: todo</h2>
              </StdPage>
            }
          />
          {/* // */}
          <Route
            path="/account/manage-keys"
            element={
              <StdPage>
                <NeedsAuth>
                  <CreatePersistentKeyPair />
                </NeedsAuth>
              </StdPage>
            }
          />
          <Route
            path="/capture"
            element={
              <StdPage>
                <NeedsAuth>
                  <LandingCapture />
                </NeedsAuth>
              </StdPage>
            }
          />
          <Route
            path="/capture/pre-recorded"
            element={
              <StdPage>
                <NeedsAuth>
                  <h1>Capture pre-recorded: TODO</h1>
                </NeedsAuth>
              </StdPage>
            }
          />
          <Route path="/capture/live" element={<CaptureLivePage />} />
        </Routes>
      </Router>
    </AppContext.Provider>
  );
}
