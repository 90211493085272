import RecordRTC from "recordrtc";
import * as lamejs from "lamejs";
import { QRCodeErrorCorrectionLevel, toCanvas as qrToCanvas } from "qrcode";
import {
  sha256,
  sha256LCHexString,
  ecdsaP256SignMessage,
} from "../../common-ts/crypto";
import {
  encodeArrayBufferAsBase64,
  hexToUintArray,
  uint8ArrayToLCHex,
} from "../../common-ts/bytes";
import { Xoroshiro128 } from "../../common-ts/prng";
import { AudioBlock } from "../../common-ts/apimodel";
import apiURL from "./apiroute";

const secondsPerChunk = 6;
const qrCodeVersion: number = 1;
const qrPayload = 16;
const errorCorrectionLevel: QRCodeErrorCorrectionLevel = "low";
const prefixWithFrameNumber = false;
const xWindowMargin = 20;
const yWindowMargin = 80; // 60px header + 10*2 padding

function wavToMp3Blob(wavBuffer: AudioBuffer): Blob {
  const channels = wavBuffer.numberOfChannels;
  const sampleRate = wavBuffer.sampleRate;
  const length = wavBuffer.length; // in samples

  // Initialize the LameJS MP3 encoder
  const mp3encoder = new lamejs.Mp3Encoder(
    channels,
    sampleRate, // if we differ from source, audio is distorted (lame does not resample for us)
    16 // 16kbps
  );

  // Concatenate the channel data into a single array
  const channelData = new Int16Array(length * channels);
  let dataIndex = 0;

  for (let i = 0; i < channels; i++) {
    const interleavedChannelData = wavBuffer.getChannelData(i);
    for (let j = 0; j < interleavedChannelData.length; j++) {
      // times 32767 because lamejs expects signed 16-bit integers
      // This is the same as Math.round(interleavedChannelData[j] * 32767).
      // It works because interleavedChannelData[j] is in the range [-1, 1]
      channelData[dataIndex++] = Math.round(interleavedChannelData[j] * 32767); // Convert from float to int16
    }
  }

  // Encode the data as MP3
  const mp3Data = mp3encoder.encodeBuffer(channelData);
  const mp3End = mp3encoder.flush();
  const fullMp3Data = new Uint8Array(mp3Data.length + mp3End.length);
  fullMp3Data.set(mp3Data);
  fullMp3Data.set(mp3End, mp3Data.length);

  // Create a Blob from the MP3 data
  return new Blob([fullMp3Data], { type: "audio/mpeg" });
}

// Function to convert a Blob to an AudioBuffer
async function blobToAudioBuffer(blob: Blob): Promise<AudioBuffer> {
  return await new AudioContext().decodeAudioData(await blob.arrayBuffer());
}

async function displayBlockIdOnCanvas(
  blockId: Uint8Array,
  frameNum: number,
  targetTimeSeconds: number,
  canvas: HTMLCanvasElement
): Promise<number> {
  // new frameNum
  console.log("Displaying blockId on canvas", blockId);
  const effectivePayload = qrPayload - (prefixWithFrameNumber ? 1 : 0); // 1 byte for frame number in sequence

  const nbFramesRequired = Math.ceil(blockId.length / effectivePayload);
  const secondsPerFrame = targetTimeSeconds / nbFramesRequired;

  // each frame starts with its sequence number in base32; Frame 1 = 1
  // 0 is reserved for optional header frames
  let seqNum = 1;

  for (let i = 0; i < nbFramesRequired; i++) {
    const subHash = blockId.slice(
      i * effectivePayload,
      (i + 1) * effectivePayload
    );

    if (prefixWithFrameNumber && seqNum > 31) {
      throw new Error(
        "Number of frames should be less than 32; current number of frames is " +
          seqNum
      );
    }

    const wrappedSubHash = prefixWithFrameNumber
      ? new Uint8Array([seqNum, ...subHash])
      : subHash;

    console.log(
      "blockId",
      uint8ArrayToLCHex(wrappedSubHash),
      "nbFramesRequired",
      nbFramesRequired
    );

    // rotate canvas at every frame to unambiguously
    // distinguish frames from each other, even if they are identical
    let rotation = (frameNum++ % 4) * 90;
    // canvas.style.scale = `${1 / dpr}`;
    canvas.style.transform = `rotate(${rotation}deg)`;

    let nbModules = 21;
    if (qrCodeVersion == 2) {
      nbModules = 25;
    } else if (qrCodeVersion == 3) {
      nbModules = 29;
    }

    let moduleSize = Math.floor(
      Math.min(
        (window.innerWidth - xWindowMargin) / nbModules,
        (window.innerHeight - yWindowMargin) / nbModules
      )
    );

    await qrToCanvas(canvas, [{ data: wrappedSubHash }], {
      errorCorrectionLevel,
      // width: 256 * dpr,
      scale: moduleSize,
      margin: 0,
      version: qrCodeVersion,
      color: {
        // /* works best with IMG_1770 */ dark: "#005085", // shade of blue, not too dark to not be "hollow" when viewed from the side, and not too bright to not bloom on the camera
        dark: "#0073bf",
        light: "#111827", // matches body bg
      },
    });

    // FIXME(jerome): use rAF instead
    await new Promise((resolve) => setTimeout(resolve, secondsPerFrame * 1000));
  }

  return frameNum;
}

function toBase32UpperCase(num: number): string {
  return num.toString(32).toUpperCase();
}

export async function startRecording(
  user_id: string,
  sessionKeyPair: CryptoKeyPair,
  sessionId: string,
  blockSequenceSeed: string
): Promise<() => Promise<void>> {
  // create a PRNG seeded with the block sequence seed
  const blockSequencePRNG = Xoroshiro128.from32BytesSeed(
    hexToUintArray(blockSequenceSeed)
  );

  let frameNum = 0;
  let blockNum = 0;
  const inputStream: MediaStream = await navigator.mediaDevices.getUserMedia({
    audio: true,
  });

  const recordRTC: RecordRTC = new RecordRTC(inputStream, {
    type: "audio",
    mimeType: "audio/wav",
    timeSlice: 1000 * secondsPerChunk,
    numberOfAudioChannels: 1, // mono
    bufferSize: 16384, // big buffer, avoid stuttering at the beginning of every chunk rank > 1
    recorderType: RecordRTC.StereoAudioRecorder, // required for wav, otherwise uses webm
    ondataavailable: async (wavBlob: Blob) => {
      const mp3Blob = wavToMp3Blob(await blobToAudioBuffer(wavBlob));

      // Compute block and send to API
      // use sha256 of block signature as block ID; use block ID as QR code
      // fetch block by ID from API to get signature, audio data, user id, etc.

      // FIXME(jerome): we need to get the timestamp of the beginning of the audio, not the end
      const now = new Date().toISOString();

      const block: AudioBlock = {
        type: "audio",
        date: now,
        user_id,
        client: "web-0.0.0",
        sessionId,
        numInSession: blockNum++,
        randomSequence: await sha256LCHexString(
          blockSequencePRNG.next().toString()
        ),
        body: {
          dataHash: await sha256LCHexString(await mp3Blob.arrayBuffer()),
          begin: "FIXME(jerome)",
          end: "FIXME(jerome)",
        },
      };

      // 4. Sign the block hash with the session key pair
      const blockJSON = JSON.stringify(block);
      console.log("THE SIGNED BLOCK JSON", blockJSON);
      const blockHash = await sha256(blockJSON);
      const blockSignature = await ecdsaP256SignMessage(
        sessionKeyPair.privateKey,
        blockHash
      );

      const blockId = (await sha256(blockSignature)).slice(0, 16);

      // FIXME(jerome): send block to API in parallel with displaying the hash in QR code sequence
      const formData = new FormData();
      formData.append("blockJSON", blockJSON);
      formData.append(
        "blockSignatureB64",
        encodeArrayBufferAsBase64(blockSignature)
      );
      formData.append(
        "dataB64",
        encodeArrayBufferAsBase64(await mp3Blob.arrayBuffer())
      );

      const postBlockResponse = await (
        await fetch(apiURL("/block"), {
          credentials: "include", // send JWT cookie "authToken"
          method: "POST",
          body: formData,
        })
      ).json();

      console.log("postBlockResponse", postBlockResponse);

      const canvas = document.getElementById("canvas") as HTMLCanvasElement;
      if (canvas) {
        frameNum = await displayBlockIdOnCanvas(
          blockId,
          frameNum,
          // FIXME(jerome): adaptive timing, depending on the overhead of compression + hashing;
          // ensure that the whole hash can be displayed in the target time regardless of the time it takes to encode and hash the data,
          // and that we efficiently use time to display qr codes for as long as possible
          secondsPerChunk - 1,
          canvas
        );
      } else {
        console.error("canvas not found");
      }
    },
  });

  recordRTC.startRecording();

  return async function () {
    if (recordRTC && recordRTC.getState() === "recording") {
      recordRTC.stopRecording(() => {
        inputStream.getTracks().forEach((track) => track.stop());
      });
    }
  };
}
