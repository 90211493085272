import React from "react";
import PageContent from "./PageContent";

export default function LandingVerify() {
  return (
    <PageContent title="Verify recordings.">
      <PageContent.MainLink to="/verify/session">
        I have the ID of a session.
      </PageContent.MainLink>
      <PageContent.MainLink to="/verify/block">
        I have the signature of an audio block.
      </PageContent.MainLink>
      <PageContent.MainLink to="/verify/video">
        I have a video containing QR codes.
      </PageContent.MainLink>
    </PageContent>
  );
}
