import React from "react";
import { Link } from "react-router-dom";

type MainLinkProps = {
  to: string;
  children: React.ReactNode;
};

export default function MainLink(props: MainLinkProps) {
  return (
    <p className="mt-6 text-lg leading-8 text-gray-300">
      <Link to={props.to} className="text-3xl text-white hover:text-orange-400">
        {props.children}
      </Link>
    </p>
  );
}
