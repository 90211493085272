import React from "react";

type ButtonProps = {
  children: React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
  addStyle?: string;
};

export default function Button(props: ButtonProps) {
  return (
    <button
      className={`bg-orange-500 text-white font-semibold px-4 py-2 rounded shadow hover:bg-orange-400 active:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 ${
        props.disabled ? "opacity-50 cursor-not-allowed" : ""
      } ${props.addStyle ? props.addStyle : ""}`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}
