import React, { useEffect, useRef } from "react";
import apiURL from "../apiroute";
import {
  AudioBlock,
  AudioBlockSchema,
  BlockDetails,
  BlockDetailsSchema,
  BlockHandle,
  BlockSessionAPIModel,
  UserAPIModel,
} from "../../../common-ts/apimodel";
import PageContent from "./PageContent";
import { useNavigate, useParams } from "react-router-dom";
import BlockSessionDetails from "./BlockSessionDetails";
import {
  ecdsaP256ImportPublicKeyRaw,
  ecdsaP256VerifyMessage,
  sha256,
  sha256LCHexString,
} from "../../../common-ts/crypto";
import { b64ToUint8Array } from "../../../common-ts/bytes";

export default function VerifySession() {
  const navigate = useNavigate();
  const { sessionId: sessionIdFromURL } = useParams();

  const [sessionId, setSessionId] = React.useState(sessionIdFromURL);
  const [blockSessionDetails, setBlockSessionDetails] = React.useState<
    { session: BlockSessionAPIModel; user: UserAPIModel } | undefined
  >();

  const [blockList, setBlockList] = React.useState<
    {
      id: string;
      index: number;
      nbBlocks: number;
    }[]
  >([]);

  const [blockVerificationStatuses, setBlockVerificationStatuses] =
    React.useState<
      Record<
        string,
        {
          status: "verified" | "error";
          error?: string;
        }
      >
    >({});

  const audioContext = useRef<AudioContext>(
    new (window.AudioContext || (window as any).webkitAudioContext)()
  );

  useEffect(() => {
    return () => {
      try {
        if (audioContext.current.state !== "closed") {
          audioContext.current.close();
        }
      } catch (e) {
        console.error("could not unmount audiocontext", e);
      }
    };
  }, [audioContext]);

  const onBlockPlay = (
    index: number,
    blockHandles: BlockHandle[],
    audioData: ArrayBuffer
  ) => {
    const blockHandle = blockHandles[index];
    blockList?.push({
      id: blockHandle.id,
      index,
      nbBlocks: blockHandles.length,
    });

    setBlockList([...blockList!]);
    (async () => {
      // FIXME(jerome): factorize with www/src/verifyBlock.ts::verifyBlock()

      const json: any = await (
        await fetch(apiURL("/block/" + blockHandle.id), {
          credentials: "include", // send JWT cookie "authToken"
        })
      ).json();

      // 0. Verify block structure
      const block: BlockDetails = BlockDetailsSchema.parse(json);
      const audioBlock: AudioBlock = AudioBlockSchema.parse(
        JSON.parse(block.blockJSON)
      );

      // 1. verify that id is sha256 of signature
      const ourBlockId = (
        await sha256LCHexString(b64ToUint8Array(block.signature))
      ).slice(0, 32);
      if (block.id !== ourBlockId) {
        blockVerificationStatuses[block.id] = {
          status: "error",
          error: "Block ID does not match signature",
        };
        setBlockVerificationStatuses({ ...blockVerificationStatuses });
        return;
      }

      // 2. verify block data hash with hash of data
      if (audioBlock.body.dataHash !== (await sha256LCHexString(audioData))) {
        blockVerificationStatuses[block.id] = {
          status: "error",
          error: "Block data hash does not match data",
        };
        setBlockVerificationStatuses({ ...blockVerificationStatuses });
        return;
      }

      // 3. verify block signature with session public key
      const sessionPublicKey: CryptoKey = await ecdsaP256ImportPublicKeyRaw(
        // FIXME(jerome): ensure blockSessionDetails is set at this point
        b64ToUint8Array(
          blockSessionDetails?.session.sessionPublicKeyB64 as string
        )
      );

      const blockHash = await sha256(block.blockJSON);
      if (
        !(await ecdsaP256VerifyMessage(
          blockHash,
          b64ToUint8Array(block.signature),
          sessionPublicKey
        ))
      ) {
        blockVerificationStatuses[block.id] = {
          status: "error",
          error: "Block signature is invalid",
        };
        setBlockVerificationStatuses({ ...blockVerificationStatuses });
        return;
      }

      blockVerificationStatuses[block.id] = {
        status: "verified",
      };
      setBlockVerificationStatuses({ ...blockVerificationStatuses });

      console.log("Block signature verified with session public key");
    })();
  };

  React.useEffect(() => {
    if (!sessionIdFromURL) return;

    (async () => {
      const sessionDetails: BlockSessionAPIModel | undefined = await fetchJSON(
        apiURL(`/block-session/${sessionId}`)
      );
      if (!sessionDetails) return;

      const user: UserAPIModel | undefined = await fetchJSON(
        apiURL("/user/" + sessionDetails.user_id)
      );

      if (!user) return;

      setBlockSessionDetails({
        session: sessionDetails,
        user: user!,
      });
    })();
  }, [sessionIdFromURL]);

  const loadAudioFile = async (url: string): Promise<ArrayBuffer> => {
    const response: Response = await fetch(url);
    // const arrayBuffer: ArrayBuffer = await response.arrayBuffer();
    // return await audioContext.current.decodeAudioData(arrayBuffer);
    return await response.arrayBuffer();
  };

  const playBufferedFiles = async (
    blockHandles: BlockHandle[],
    // audioBufferList: AudioBuffer[],
    audioDataList: ArrayBuffer[],
    currentBufferIndex: number
  ): Promise<void> => {
    if (currentBufferIndex >= blockHandles.length) return;

    const audioData = audioDataList.shift()!;
    const audioDataClone = audioData.slice(0); // because decodeAudioData() consumes the buffer
    const audioBuffer = await audioContext.current.decodeAudioData(audioData);

    const bufferSource: AudioBufferSourceNode =
      audioContext.current.createBufferSource();
    bufferSource.buffer = audioBuffer;
    bufferSource.connect(audioContext.current.destination);
    bufferSource.start();

    onBlockPlay(currentBufferIndex, blockHandles, audioDataClone);

    currentBufferIndex++;
    bufferSource.onended = async () => {
      await playBufferedFiles(blockHandles, audioDataList, currentBufferIndex);
    };

    if (currentBufferIndex < blockHandles.length) {
      const nextAudioData = await loadAudioFile(
        dataURLForBlockId(blockHandles[currentBufferIndex].id)
      );
      audioDataList.push(nextAudioData);
    }
  };

  const loadAndPlayAudioFiles = async (
    blockHandles: BlockHandle[]
  ): Promise<void> => {
    if (blockHandles.length < 1) return;

    const initialBuffer = await loadAudioFile(
      dataURLForBlockId(blockHandles[0].id)
    );
    const audioDataList: ArrayBuffer[] = [initialBuffer];
    const currentBufferIndex = 0;
    await playBufferedFiles(blockHandles, audioDataList, currentBufferIndex);
  };

  const handleChangeSessionId = (value: string) => {
    setSessionId(value);
    setBlockSessionDetails(undefined);
    audioContext.current.suspend();
    navigate(`/verify/session`);
  };

  const handleLoadSessionDetailsClick = async () => {
    if (!sessionId) return;

    // change window url, will trigger the fetch in useEffect()
    navigate(`/verify/session/${sessionId}`);
  };

  const handlePlayClick = async (blockHandles: BlockHandle[]) => {
    if (audioContext.current.state === "suspended") {
      audioContext.current.resume();
    }

    await loadAndPlayAudioFiles(blockHandles);
  };

  return (
    <PageContent title="Verify Audio Session.">
      <PageContent.InputText
        placeholder="The Session ID here"
        onChange={handleChangeSessionId}
        value={sessionId}
      />
      {blockSessionDetails === undefined && (
        <PageContent.MainButton
          onClick={handleLoadSessionDetailsClick}
          disabled={!sessionId}
        >
          Load session details
        </PageContent.MainButton>
      )}
      {blockSessionDetails && (
        <BlockSessionDetails
          session={blockSessionDetails.session}
          onVerifyClick={() => alert("TODO")}
          onPlayClick={handlePlayClick}
        />
      )}
      {blockList.length > 0 && (
        <div>
          <h3 className="text-3xl font-semibold tracking-tighter text-gray-300 sm:text-4xl mt-8">
            Blocks Verifications
          </h3>
          <ul>
            {blockList.map((blockVerification, index) => (
              <li key={index} className="mt-2">
                <span className="text-orange-500 font-bold">{blockVerification.index + 1}/{blockVerification.nbBlocks}{" "}</span>
                ID: <span className="whitespace-pre font-mono inline">{blockVerification.id}</span> -{" "}
                {!(blockVerification.id in blockVerificationStatuses) ? (
                  "pending"
                ) : blockVerificationStatuses[blockVerification.id].status ===
                  "verified" ? (
                  <>✅ verified</>
                ) : (
                  <span className="text-red-500">
                    ❌ Verification failed:{" "}
                    {blockVerificationStatuses[blockVerification.id].error}
                  </span>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </PageContent>
  );
}

export async function fetchJSON<T>(url: string): Promise<T | undefined> {
  let response: Response;

  try {
    response = await fetch(url);
  } catch (e) {
    alert(`An error occurred while fetching ${url}`);
    return;
  }

  if (response.status !== 200) {
    if (response.status === 404) {
      alert("Not found");
    } else {
      alert(`An error occurred while fetching ${url}`);
    }
    return;
  }

  return await response.json();
}

function dataURLForBlockId(blockId: string): string {
  return apiURL(`/block/${blockId}/data`);
}
