import { decode as cborDecode } from "cbor-js";

import {
  b64ToUint8Array,
  uint8ArrayToB64Url,
  uint8ArrayToLCHex,
} from "./bytes";

// ////////////////////////////////////////////////////////////////////////////
// ECDSA P-256 (aka ES256)
// ////////////////////////////////////////////////////////////////////////////

export const supportedAlgorithm = -7; // ES256

export async function ecdsaP256VerifyMessage(
  message: ArrayBufferLike,
  signature: ArrayBufferLike,
  publicKey: CryptoKey
): Promise<boolean> {
  return await crypto.subtle.verify(
    {
      name: "ECDSA",
      hash: "SHA-256",
    },
    publicKey,
    signature,
    message
  );
}

export async function ecdsaP256SignMessage(
  privateKey: CryptoKey,
  data: ArrayBuffer
): Promise<ArrayBuffer> {
  return await crypto.subtle.sign(
    {
      name: "ECDSA",
      hash: { name: "SHA-256" },
    },
    privateKey,
    data
  );
}

// COSE (CBOR Object Signing and Encryption) format
// is the format used by WebAuthn to represent public keys
// https://www.w3.org/TR/webauthn/#sctn-encoded-credPubKey-examples
export async function ecdsaP256ConvertPublicKeyToRaw(
  cose: Uint8Array
): Promise<Uint8Array> {
  // Extract the public key from COSE_Key format
  const decodedPublicKey = await cborDecode(cose.buffer);

  // Cose_Key layout: see https://developers.yubico.com/WebAuthn/WebAuthn_Developer_Guide/WebAuthn_Client_Registration.html

  // Check if the public key is EC2
  if (decodedPublicKey["1"] !== 2) {
    throw new Error("Unsupported key type");
  }

  // Check if the public key algorithm is ES256
  if (decodedPublicKey["3"] !== supportedAlgorithm) {
    throw new Error("Unsupported algorithm");
  }

  // Check if the curve is P-256
  if (decodedPublicKey["-1"] !== 1) {
    throw new Error("Unsupported curve");
  }

  const xBytes = decodedPublicKey["-2"];
  const yBytes = decodedPublicKey["-3"];

  return new Uint8Array([0x04, ...xBytes, ...yBytes]);
}

export async function ecdsaP256ImportPublicKeyRaw(
  rawBytes: Uint8Array
): Promise<CryptoKey> {
  if (rawBytes.length !== 65 || rawBytes[0] !== 0x04) {
    throw new Error(
      "Invalid raw ECDSA P-256 public key format; len=" + rawBytes.length
    );
  }

  const publicKey = await crypto.subtle.importKey(
    "raw",
    rawBytes,
    {
      // ES256 with P-256 (algorithm -7  in https://w3c.github.io/webauthn/#sctn-ecdsa-algorithm)
      name: "ECDSA",
      namedCurve: "P-256",
      hash: "SHA-256",
    },
    false, // not extractable
    ["verify"]
  );

  return publicKey;
}

export function ecdsaP256RawSignatureFromDERSignature(
  derSignature: ArrayBufferLike
): ArrayBufferLike {
  const { r, s } = ecdsaP256ExtractRSPointsFromDERSignature(
    new Uint8Array(derSignature)
  );
  const rawSignature = new Uint8Array(r.length + s.length);
  rawSignature.set(r, 0);
  rawSignature.set(s, r.length);

  return rawSignature;
}

/*
 * This function extracts the r and s components from an ECDSA P-256 DER-encoded
 * signature. DER-encoded signatures can have varying lengths depending on the
 * size of the r and s components. In practice, most signatures will be between
 * 70 and 72 bytes, but they can be as short as 68 bytes or as long as 74 bytes
 * in rare cases.
 *
 * Structure of a DER-encoded signature:
 * 1. SEQUENCE (1 byte)
 * 2. SEQUENCE length (1 byte)
 * 3. INTEGER tag for r (1 byte)
 * 4. Length of r (1 byte)
 * 5. r value (32 or 33 bytes)
 * 6. INTEGER tag for s (1 byte)
 * 7. Length of s (1 byte)
 * 8. s value (32 or 33 bytes)
 */
export function ecdsaP256ExtractRSPointsFromDERSignature(
  derSignature: Uint8Array
): {
  r: Uint8Array;
  s: Uint8Array;
} {
  let offset = 0;

  // Validate and skip the SEQUENCE tag
  if (derSignature[offset++] !== 0x30) {
    throw new Error("Invalid DER signature: expected SEQUENCE tag");
  }

  // Skip the SEQUENCE length
  const sequenceLength = derSignature[offset++];
  if (sequenceLength > derSignature.length - offset) {
    throw new Error("Invalid DER signature: invalid SEQUENCE length");
  }

  // Validate and skip the INTEGER tag for r
  if (derSignature[offset++] !== 0x02) {
    throw new Error("Invalid DER signature: expected INTEGER tag for r");
  }

  // Get the length of r and extract its value
  const rLength = derSignature[offset++];
  let r = derSignature.slice(offset, offset + rLength);
  offset += rLength;

  // Remove leading 0 byte from r if present
  if (r[0] === 0) {
    r = r.slice(1);
  }

  // Validate and skip the INTEGER tag for s
  if (derSignature[offset++] !== 0x02) {
    throw new Error("Invalid DER signature: expected INTEGER tag for s");
  }

  // Get the length of s and extract its value
  const sLength = derSignature[offset++];
  let s = derSignature.slice(offset, offset + sLength);

  // Remove leading 0 byte from s if present
  if (s[0] === 0) {
    s = s.slice(1);
  }

  return { r: new Uint8Array(r), s: new Uint8Array(s) };
}

export async function ecdsaP256GenerateKeyPair(
  exportable: boolean
): Promise<CryptoKeyPair> {
  return (await crypto.subtle.generateKey(
    {
      name: "ECDSA",
      namedCurve: "P-256",
      hash: { name: "SHA-256" },
    },
    exportable,
    ["sign", "verify"]
  )) as CryptoKeyPair;
}

// 65 bytes (1 byte for the type == uncompressed  + 32 bytes for x + 32 bytes for y )
export async function ecdsaP256ExportPublicKeyRaw(
  key: CryptoKey
): Promise<ArrayBuffer> {
  return (await crypto.subtle.exportKey("raw", key)) as ArrayBuffer;
}

export async function ecdsaP256ExportPrivateKeyPkcs8(
  key: CryptoKey
): Promise<ArrayBuffer> {
  return (await crypto.subtle.exportKey("pkcs8", key)) as ArrayBuffer;
}

// ////////////////////////////////////////////////////////////////////////////
// Ed25519
// ///////////////////////////////////////////////////////////////////////////

export async function ed25519VerifyMessage(
  publicKey: CryptoKey,
  message: Uint8Array,
  signature: Uint8Array
): Promise<boolean> {
  return await crypto.subtle.verify(
    { name: "NODE-ED25519" },
    publicKey,
    signature,
    message
  );
}

export async function ed25519SignMessage(
  privateKey: CryptoKey,
  message: Uint8Array
): Promise<Uint8Array> {
  const signature = await crypto.subtle.sign(
    { name: "NODE-ED25519" },
    privateKey,
    message
  );
  return new Uint8Array(signature);
}

export async function ed25519ImportRawPublicKey(
  rawKey: Uint8Array
): Promise<CryptoKey> {
  return await crypto.subtle.importKey(
    "jwk",
    {
      kty: "OKP",
      crv: "Ed25519",
      x: uint8ArrayToB64Url(rawKey),
    },
    { name: "NODE-ED25519", namedCurve: "NODE-ED25519" }, // { name: "Ed25519", namedCurve: "Ed25519" } in NodeJs 19
    false, // not extractable
    ["verify"]
  );
}

export async function ed25519ImportPemPrivateKey(
  privateKeyPem: string
): Promise<CryptoKey> {
  const privateKeyBuffer = pemToDer(privateKeyPem);
  return await crypto.subtle.importKey(
    "pkcs8",
    privateKeyBuffer,
    {
      name: "NODE-ED25519",
      namedCurve: "NODE-ED25519",
    },
    true,
    ["sign"]
  );
}

export async function ed25519ImportPemPublicKey(
  publicKeyPem: string
): Promise<CryptoKey> {
  const publicKeyBuffer = pemToDer(publicKeyPem);
  return await crypto.subtle.importKey(
    "spki",
    publicKeyBuffer,
    {
      name: "NODE-ED25519",
      namedCurve: "NODE-ED25519",
    },
    true,
    ["verify"]
  );
}

// ////////////////////////////////////////////////////////////////////////////
// Other stuff
// ///////////////////////////////////////////////////////////////////////////

function pemToDer(pem: string): Uint8Array {
  return b64ToUint8Array(
    pem
      .replace(/-----BEGIN ([A-Z\s]+)-----/, "")
      .replace(/-----END ([A-Z\s]+)-----/, "")
      .replace(/\s+/g, "")
  );
}

export function constantTimeComparison(
  buffer1: ArrayBufferLike,
  buffer2: ArrayBufferLike
) {
  const view1 = new Uint8Array(buffer1);
  const view2 = new Uint8Array(buffer2);
  const maxLength = Math.max(view1.length, view2.length);
  let result = view1.length ^ view2.length; // will be != 0 if lengths are different

  for (let i = 0; i < maxLength; i++) {
    const a = view1[i] || 0;
    const b = view2[i] || 0;
    result |= a ^ b;
  }

  return result === 0;
}

export async function sha256(
  data: ArrayBufferLike | string
): Promise<Uint8Array> {
  let buf: ArrayBufferLike;
  if (typeof data === "string") {
    buf = new TextEncoder().encode(data);
  } else {
    buf = data;
  }

  return new Uint8Array(await crypto.subtle.digest("SHA-256", buf));
}

export async function sha256LCHexString(
  data: ArrayBufferLike | string
): Promise<string> {
  return uint8ArrayToLCHex(await sha256(data));
}
