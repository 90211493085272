import { uint8ArrayToLCHex } from "./bytes";

export class Xoroshiro128 {
  private s1: bigint; // Internal state 1
  private s2: bigint; // Internal state 2

  // Constructor accepts two BigInt seed values as arguments.
  constructor(seed1: bigint, seed2: bigint) {
    this.s1 = seed1;
    this.s2 = seed2;
  }

  static from32BytesSeed(seed: Uint8Array): Xoroshiro128 {
    if (seed.byteLength !== 32) {
      throw new Error("seed must be 32 bytes");
    }

    const seedHex = uint8ArrayToLCHex(seed);
    return new Xoroshiro128(
      BigInt(`0x${seedHex.substring(0, 16)}`),
      BigInt(`0x${seedHex.substring(16, 32)}`)
    );
  }

  // The next() function generates the next random number in the sequence as a float in the range [0, 1).
  next(): number {
    // Store the current values of the internal states in local variables.
    const s0: bigint = this.s1;
    let s1: bigint = this.s2;

    // Calculate the result using the xoroshiro128** algorithm.
    // Multiply the current state s0 by a constant factor (0x2545F4914F6CDD1D)
    // and take the result modulo 2^64.
    const result: bigint = BigInt.asUintN(
      64,
      s0 * BigInt("0x2545F4914F6CDD1D")
    );

    // Update the internal states using the xoroshiro128** algorithm.
    s1 ^= s0;
    this.s1 = BigInt.asUintN(
      64,
      (s0 << BigInt(24)) ^ s1 ^ (s1 << BigInt(16)) ^ (s1 >> BigInt(37))
    );
    this.s2 = BigInt.asUintN(64, (s1 << BigInt(37)) ^ s1 ^ (s1 >> BigInt(24)));

    // Convert the BigInt result to a Number, divide by 2^64 to obtain a float in the range [0, 1),
    // and return the result.
    return Number(result) / 2 ** 64;
  }
}
