import React from "react";

type MainHeadingProps = {
  children: React.ReactNode;
};

export default function MainHeading(props: MainHeadingProps) {
  return (
    <h2 className="text-4xl font-bold tracking-tight text-orange-500 sm:text-6xl">
      {props.children}
    </h2>
  );
}
