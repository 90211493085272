import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  BlockSessionAPIModel,
  UserAPIModel,
} from "../../../common-ts/apimodel";
import { fetchJSON } from "./VerifySession";
import apiURL from "../apiroute";
import PageContent from "./PageContent";

export default function BrowsePublicSessions() {
  const { twitterUsername: twitterUsernameFromURL } = useParams();

  const [userNotFound, setUserNotFound] = React.useState<boolean | undefined>();
  const [user, setUser] = React.useState<UserAPIModel | undefined>();
  const [sessionList, setSessionList] = React.useState<
    BlockSessionAPIModel[] | undefined
  >();

  React.useEffect(() => {
    (async () => {
      if (!twitterUsernameFromURL) return;

      // determine user Id for twitter username
      const user: UserAPIModel | undefined = await fetchJSON(
        apiURL(
          "/find-user/by-twitter-username/" +
            encodeURIComponent(twitterUsernameFromURL)
        )
      );

      if (!user) {
        setUserNotFound(true);
        return;
      } else {
        setUserNotFound(false);
        setUser(user);
      }

      // fetch public sessions for user
      const sessions: BlockSessionAPIModel[] | undefined = await fetchJSON(
        apiURL(`/list-public-block-sessions/${user.id}`)
      );
      if (!sessions) return;
      setSessionList(sessions);
    })();
  }, [twitterUsernameFromURL]);

  if (userNotFound === true) {
    return <PageContent title={`User not found: ${twitterUsernameFromURL}`} />;
  }

  return (
    <PageContent title={`Public recordings for ${twitterUsernameFromURL}`}>
      <ul className="space-y-4 mt-7">
        {sessionList?.map((session) => {
          const url = `/verify/session/${session.id}`;
          return (
            <li
              key={session.id}
              className="p-4 bg-gray-800 rounded-md shadow-sm"
            >
              <h3 className="text-lg font-semibold">
                <Link
                  to={url}
                  className="text-orange-500 hover:text-orange-400"
                >
                  {new Date(session.commitDate).toDateString()} &mdash; {session.unsigned?.name || "Unnamed Session"}
                </Link>
              </h3>
              <div className="space-y-2">
                <p>
                  <span className="font-medium">ID:</span>{" "}
                  <Link
                    to={url}
                  >
                    {session.id}
                  </Link>
                </p>
              </div>
            </li>
          );
        })}
      </ul>
    </PageContent>
  );
}
