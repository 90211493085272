import {
  AuthTokenJWTPayload,
  PersistentKeyOwnershipClaim,
  PostPublicKeyCommitRequestBody,
  PostPublicKeyRequestResponseBody,
  PublicKeyOwnedAPIModel,
} from "../../common-ts/apimodel";
import {
  b64UrlToUint8Array,
  decodeBase64AsArrayBuffer,
  encodeArrayBufferAsBase64,
} from "../../common-ts/bytes";
import apiURL from "./apiroute";
import {
  createPersistentECDSAP256KeyPairOnYubikey,
  signOnYubikey,
} from "./yubikey";

export async function createPersistentKey(
  authData: AuthTokenJWTPayload
): Promise<PublicKeyOwnedAPIModel> {
  const publicKeyInitResponse: PostPublicKeyRequestResponseBody = await (
    await fetch(apiURL("/public-key/request"), {
      credentials: "include", // send JWT cookie "authToken"
      method: "POST",
    })
  ).json();

  // we need to sign the details signature provided by the api
  // with the private key of the persistent credentials to prove ownership
  // of the persistent credentials
  const messageToSign = decodeBase64AsArrayBuffer(
    publicKeyInitResponse.derEncodedSignature
  );

  // challenge must be stateful server side and consumed when first validated to avoid replay attacks
  const authenticatorResponse = await createPersistentECDSAP256KeyPairOnYubikey(
    messageToSign
  );

  console.log("AUTHENTICATORRESPONSE", authenticatorResponse);

  if (!authenticatorResponse) {
    throw new Error("Failed to create persistent ECDSA P-256 key pair");
  }

  const publicOwnershipClaim: PersistentKeyOwnershipClaim = {
    topic: "genueen.com:persistent_key_ownership_claim", // FIXME(jerome): url to key verification?
    keyId: publicKeyInitResponse.details.keyId,
    user_id: authData.user.id,
    twitter_username: authData.user.twitter_username,
    // FIXME(jerome): emission date and expiration? Should keys expire?
  };

  const publicOwnershipProof = await signOnYubikey(
    b64UrlToUint8Array(authenticatorResponse.persistentKeyId),
    new TextEncoder().encode(JSON.stringify(publicOwnershipClaim))
  );

  const body: PostPublicKeyCommitRequestBody = {
    init: publicKeyInitResponse,
    algorithm: "ecdsa-p256",
    yubikeyKeyIdB64: authenticatorResponse.persistentKeyId,

    persistentKeyProof: {
      // We need to pass the whole AuthenticatorResponse to the API, for it to be able to validate the included signature
      // this is because the signature is not only that of the challenge, but also of the clientDataJSON and the authenticatorData;
      // effectively, what is signed is concatBytes(authenticatorData, SHA-256(clientDataJSON)), where clientDataJSON is a JSON string
      // containing the WebAuthn Relying Party challenge among other things

      clientDataJSON: encodeArrayBufferAsBase64(
        authenticatorResponse.clientDataJSON
      ),
      attestationObject: encodeArrayBufferAsBase64(
        authenticatorResponse.attestationObject
      ),
    },

    // for 3rd parties (ie, not the WebAuthn Relying Party)
    publicOwnershipProof: {
      authenticatorData: encodeArrayBufferAsBase64(
        publicOwnershipProof.authenticatorData
      ),
      clientDataJSON: new TextDecoder().decode(
        // FIXME(jerome): could be better if harmonized with the other clientDataJSON (b64 buffer)?
        // although: the other is straight out of yubikey, this is for public use; maybe change name to avoid confusion?
        publicOwnershipProof.clientDataJSON
      ),
      derEncodedSignature: encodeArrayBufferAsBase64(
        publicOwnershipProof.derEncodedSignature
      ),
    },
  };

  const publicKey: PublicKeyOwnedAPIModel = await (
    await fetch(apiURL("/public-key/commit"), {
      credentials: "include", // send JWT cookie "authToken"
      method: "POST",
      body: JSON.stringify(body),
    })
  ).json();

  console.log("createPersistentCredentials result", publicKey);
  return publicKey;
}
